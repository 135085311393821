import React from 'react'
import {BsClipboardData} from "react-icons/bs";
import {DiCodeigniter} from "react-icons/di";
import {GiCommercialAirplane} from "react-icons/gi";
import {FaMobileAlt} from "react-icons/fa";
import { BiRocket } from "react-icons/bi";
import './AnimatedName.css';
const homeConfig = {

    greeting:
    <h1 className="heading">
    Hi! I'm <strong className="main-name">Aditya Singh</strong>
</h1>,
titles: [
    "A Software Engineer",
    "A Web Development Engineer",
    "A React & Next.js Expert",
    "An Cloud Practitioner",
    "An Agile Methodology Enthusiast"
],
    about: {
        "start": "Embarking on a tech-forward career, I've leveraged my skills in software engineering and web development across diverse roles since graduating. My journey through different industries, from healthcare to aviation, has been driven by a passion for solving complex challenges and enhancing user experiences with cutting-edge technologies.",
        "exit": "Proficient in a broad spectrum of technologies including React, Next.js, Java, Node.js, and AWS, I excel at building scalable and efficient solutions. My dedication extends beyond code; it's about making impactful contributions through technology, with a keen interest in automating and optimizing processes for the future."
    },
    workTimeline: [
        {
            "id": "work-4",
            "title": "Software Engineer",
            "company": "Allegiant Air",
            "description": "Designed and implemented UI components using React and NextJS, improved data retrieval efficiency by 30% with GraphQL API, wrote unit tests with Jest, assisted in CI/CD pipeline, and optimized deployment with Docker and Kubernetes.",
            "date": "2023-Present",
            "icon": <BiRocket/>,
            "tags": ["react", "nextjs", "graphql", "jest", "ci/cd", "docker", "kubernetes"]
        },
        {
            "id": "work-3",
            "title": "Software Engineer",
            "company": "Techno-Comp INC",
            "description": "Developed reusable React components, contributed to Agile sprints, implemented Java Spring RESTful APIs, managed AWS deployment, and optimized Postgres queries.",
            "date": "2023-2023",
            "icon": <DiCodeigniter/>,
            "tags": ["react", "java", "spring", "aws", "postgres", "agile"]
        },
        {
            "id": "work-2",
            "title": "Web Development Engineer",
            "company": "Bhaktivedanta Hospital & Research Institute",
            "description": "Streamlined Pathology department's processes, engineered REST APIs with Node.js, implemented MongoDB, and automated workflows.",
            "date": "2020-2020",
            "icon": <FaMobileAlt/>,
            "tags": ["node.js", "rest", "mongodb", "automation"]
        },
        {
            "id": "work-1",
            "title": "Software Engineer",
            "company": "RAA Technocrats",
            "description": "Conducted requirements analysis, enhanced web apps with PHP, HTML, CSS, Bootstrap, and collaborated on unit testing.",
            "date": "2019-2020",
            "icon": <GiCommercialAirplane/>,
            "tags": ["php", "html", "css", "bootstrap", "testing"]
        }
    ]
    
}


export default homeConfig