import React, { useEffect, useRef } from 'react';
import './AnimatedBackground.css';

const AnimatedBackground = () => {
  const canvasRef = useRef(null);
  const animationFrameId = useRef();
  const targetRef = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2, radius: 150 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let width = canvas.width = window.innerWidth;
    let height = canvas.height = window.innerHeight;

    const getDistance = (p1, p2) => Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));

    class Circle {
      constructor(pos, rad, color) {
        this.pos = pos;
        this.radius = rad;
        this.color = color;
        this.active = 0;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = `rgba(255,255,255,${this.active})`;
        ctx.fill();
      }
    }

    const points = [];
    for (let x = 0; x < width; x += width / 20) {
      for (let y = 0; y < height; y += height / 20) {
        const px = x + Math.random() * width / 20;
        const py = y + Math.random() * height / 20;
        points.push({
          x: px,
          y: py,
          vx: (Math.random() - 0.5) * 0.5,
          vy: (Math.random() - 0.5) * 0.5,
          originX: px,
          originY: py,
          circle: new Circle({ x: px, y: py }, 2 + Math.random() * 2, 'rgba(255,255,255,0.3)')
        });
      }
    }

    points.forEach(point => {
      let closest = [];
      points.forEach(target => {
        if (point === target) return;
        if (closest.length < 5) {
          closest.push(target);
        } else {
          closest.sort((a, b) => getDistance(point, a) - getDistance(point, b));
          if (getDistance(point, target) < getDistance(point, closest[4])) {
            closest[4] = target;
          }
        }
      });
      point.closest = closest;
    });

    const mouseMove = (e) => {
      targetRef.current = { x: e.clientX, y: e.clientY };
    };

    const resize = () => {
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('resize', resize);

    const animate = () => {
      ctx.clearRect(0, 0, width, height);

      points.forEach(point => {
        // Move each point by its velocity
        point.x += point.vx;
        point.y += point.vy;

        // Bounce points off the edges of the canvas
        if (point.x <= 0 || point.x >= width) point.vx *= -1;
        if (point.y <= 0 || point.y >= height) point.vy *= -1;

        // Calculate opacity based on distance to the mouse pointer for the circle
        let distanceToMouse = getDistance(targetRef.current, point);
        point.circle.active = distanceToMouse < targetRef.current.radius ? (1 - distanceToMouse / targetRef.current.radius) : 0;

        point.closest.forEach(closest => {
          ctx.beginPath();
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(closest.x, closest.y);
          // Fading effect for lines
          const opacity = 1 - getDistance({x: point.x, y: point.y}, targetRef.current) / targetRef.current.radius;
          ctx.strokeStyle = `rgba(156,217,249,${opacity})`;
          ctx.stroke();
        });

        point.circle.draw();
      });

      animationFrameId.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('resize', resize);
      cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',zIndex: -1 }}></canvas>;
}

export default AnimatedBackground;
