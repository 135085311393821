import React from 'react';
import picture from '../assets/images/picture.jpeg';
import { Link } from 'react-router-dom'; // Import Link
import "./HomePage.css";


const HomePage = () => {
  return (
    
    <div className="homepage-container">

      <div className="text-content">
        <h1>Aditya Singh</h1>
        <p> 
          Welcome to my corner of the internet! I'm Aditya Singh, a dedicated and passionate Computer Science professional hailing from the vibrant city of Mumbai, India, now residing in the bustling hub of Hoboken, NJ, USA. My academic journey led me to the prestigious Stevens Institute of Technology, where I earned my Master of Science in Computer Science, a journey embellished with the honor of the Graduate Masters Scholarship in Spring 2022. Prior to this, I laid my technical foundation with a Bachelor of Engineering in Information Technology from the University of Mumbai.
          <br/><br/>
          My quest for knowledge didn't stop at academia. I've honed my skills through rigorous courses in .Net Technology & Cross Platform Application Development and Data Structures & Algorithm Certification.
          <br/><br/>
          The tech landscape is vast, but I've navigated it with tools and languages that have become second nature to me. From Java to Python, JavaScript, and beyond into the realms of HTML, CSS, NodeJS, REACT, and more, I've embraced the diversity of the field. My toolkit is replete with the latest in technology, including Slack, GitHub, Visual Studio, enhancing my projects and collaborations.
          <br/><br/>
          My professional narrative is a testament to my growth and adaptability. At Allegiant Air and Techno-Comp INC, I've contributed as a Software Engineer. These roles underscored my technical prowess and my commitment to elevating the teams and projects I was part of.
          <br/><br/>
          Academically, I've ventured into research projects like predicting chest diseases using X-ray images and developing a community-driven platform, City Watch Web App, showcasing my ability to leverage technology for societal benefit.
          <br/><br/>
          You can reach out to me at asingh96@stevens.edu.
        </p>
        <p className='wanttoknowmore'>Want to know more about me? <Link to="/chatbot">Click here</Link> to find out.</p>
      </div>
      <div className="image-content">
        <img src={picture} alt="Aditya Singh" />
      </div>
    </div>
  );
}

export default HomePage;
