import React, { useState } from 'react';
import axios from 'axios';
import { info } from './info';
import './Chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]); // To store the conversation
  const [userInput, setUserInput] = useState(''); // To track the user's current input
  const [showPlaceholder, setShowPlaceholder] = useState(true); // New state to show placeholder

  // Function to call the OpenAI API and get a response
  const personalInfoSummary = `This is my background ${info}`;

// Adjusted function to include a check or prepend personal info
const fetchChatResponse = async (message) => {
  // Lowercase the message for consistent matching
  const lowercasedMessage = message.toLowerCase();

  // Define keywords and phrases that relate to your information
  const personalInfoKeywords = [
    "background", "aditya", "experience", "projects", "education", "skills", "contact",
    "him", "you", "your", "interests", "qualifications", "he", "technologies", "about",
    "his", "it", "more", "hobbies", "certifications", "achievements", "awards", "career",
    "work", "professional", "job", "position", "role", "company", "employer", "team",
    "contribution", "development", "programming", "languages", "tools", "databases",
    "frameworks", "libraries", "API", "technology stack", "portfolio", "website",
    "application", "app", "project details", "research", "publications", "papers", "talks",
    "presentations", "workshops", "conferences", "seminars", "volunteering", "mentorship",
    "leadership", "scholarship", "grant", "fellowship", "testimonials", "reviews",
    "feedback", "endorsements", "contacts", "networking", "social media", "LinkedIn",
    "GitHub", "portfolio link", "email address", "phone number", "location", "address",
    "city", "country", "sponsorship", "visa"
  ];
  
  // Check if the message contains any of the keywords
  const containsKeyword = personalInfoKeywords.some(keyword => lowercasedMessage.includes(keyword));

  let finalMessage;

  if (containsKeyword) {
    // If the message contains a keyword, prepare a response based on your info
    finalMessage = `${personalInfoSummary} ${message}`;
  } else {
    // If not, kindly inform the user that the chatbot can only answer questions about you
    return "I'm here to provide information about Aditya. Please ask about Aditya's background, skills, projects, or other professional information.";
  }

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: finalMessage }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      }
    );
    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error('There was an error fetching the chat response:', error);
    return "Sorry, I couldn't process that.";
  }
};;
const handleInputChange = (e) => {
    setUserInput(e.target.value);
    if (showPlaceholder) {
      setShowPlaceholder(false);
    }
  };

  // Handler for sending messages
  const handleSendMessage = async (event) => {
    event.preventDefault();
    const botResponse = await fetchChatResponse(userInput);
    setMessages([...messages, { text: userInput, sender: 'user' }, { text: botResponse, sender: 'bot' }]);
    setUserInput('');
  };

  return (
    <div className="chat-container">
      <div className={`messages-container ${showPlaceholder ? '' : 'active-placeholder'}`}>
        {/* If no messages, show the placeholder */}
        {messages.length === 0 && showPlaceholder && (
          <div className="placeholder-questions">
            <h1>Hi ! Welcome to Aditya's personalised chat bot</h1>
            <p> </p>
            <h2>You can ask questions like :- </h2>
            <p>What is your experience?</p>
            <p>Tell me about your skills.</p>
            <p>Where have you studied</p>
            <p>Tell me about yourself</p>
            {/* Add more example questions here */}
          </div>
        )}
        {messages.map((msg, index) => (
          <p key={index} className={msg.sender === 'user' ? 'user-message' : 'bot-message'}>{msg.text}</p>
        ))}
      </div>
      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
           // Hide placeholder on input focus
          placeholder="Type your message here..."
        />
        <button type='submit'>
        Search
      </button>
      </form>
    </div>
  );
};


export default Chatbot;
