import {BiGitRepoForked} from "react-icons/bi";
import {AiFillGithub, AiFillEye} from "react-icons/ai";
import {ImBook} from "react-icons/im";

import sklearnGenetic from "../images/sklearn_genetic_opt.png"
import graphEmbeddings from "../images/graph_embeddings.png"
import kafkaMl from "../images/kafkaml.png"
import portfolio from "../images/portfolio.png"
import chest_diseases from "../images/chest_diseases_using_xray.webp"
import city_watch from "../images/city-watch-image.webp"
import food_ordering from "../images/food-ordering-app.png"
import React from 'react'

const projectConfig = [
    {
        id: "project-5",
        title: "Food Ordering App",
        links: [
            {
                name: "repo",
                url: "https://github.com/rodrigo-arenas/Sklearn-genetic-opt",
                icon: <AiFillGithub/>,
            },
            {
                name: "fork",
                url: "https://github.com/rodrigo-arenas/Sklearn-genetic-opt/fork",
                icon: <BiGitRepoForked/>,
            },
            {
                name: "subscription",
                url: "https://github.com/rodrigo-arenas/Sklearn-genetic-opt/subscription",
                icon: <AiFillEye/>,
            },
            {
                name: "docs",
                url: "https://sklearn-genetic-opt.readthedocs.io/en/stable/",
                icon: <ImBook/>,
            }
        ],
        image: food_ordering,
        description: "The Food Ordering App is a cutting-edge platform designed for seamless online food ordering. Leveraging the latest in React, HTML, CSS, and Tailwind CSS, it offers a dynamic and responsive user interface that makes finding and ordering from your favorite restaurants a breeze. Engineered for food lovers seeking convenience and a rich dining experience, the app features real-time updates, comprehensive browser and device compatibility, and customizable styling options. With its focus on user experience, from menu exploration to real-time delivery tracking, the Food Ordering App is the go-to choice for efficient, enjoyable, and accessible food ordering, all at your fingertips.",
        target: "_blank"
    },
    {
        id: "project-3",
        title: "Web Portfolio",
        links: [
            {
                name: "repo",
                url: "https://github.com/rodrigo-arenas/kafkaml-anomaly-detection",
                icon: <AiFillGithub/>
            },
            {
                name: "fork",
                url: "https://github.com/rodrigo-arenas/kafkaml-anomaly-detection/fork",
                icon: <BiGitRepoForked/>
            },
            {
                name: "subscription",
                url: "https://github.com/rodrigo-arenas/kafkaml-anomaly-detection/subscription",
                icon: <AiFillEye/>
            }
        ],
        image: portfolio,
        description: "This web portfolio showcases a dynamic interface built with React JS, integrating multiple components for a seamless user experience. A key feature is the intelligent chatbot, developed using OpenAI's API, which is adept at answering queries related to Aditya Singh. The chatbot is meticulously trained to provide informative and interactive dialogue, enhancing user engagement and offering a glimpse into Aditya's professional world.",
        target: "_blank"
    },
    {
        id: "project-2",
        title: "City Watch",
        links: [
            {
                name: "repo",
                url: "https://github.com/rodrigo-arenas/pyworkforce",
                icon: <AiFillGithub/>
            },
            {
                name: "fork",
                url: "https://github.com/rodrigo-arenas/pyworkforce/fork",
                icon: <BiGitRepoForked/>
            },
            {
                name: "subscription",
                url: "https://github.com/rodrigo-arenas/pyworkforce/subscription",
                icon: <AiFillEye/>
            },
            {
                name: "docs",
                url: "https://pyworkforce.readthedocs.io/en/stable/",
                icon: <ImBook/>,
            }
        ],
        image: city_watch,
        description: "The project is a web-based reporting tool aimed at improving engagement among local Hoboken residents, including students. The app will provide an accessible platform for individuals to report issues or concerns in their community.The goal of the project is to increase community involvement and communication between residents and local authorities, fostering a more engaged and connected community. With a user-friendly interface and intuitive reporting process, the web-app will strive to make a positive impact on the daily lives of Hoboken residents.",
        target: "_blank"
    },
    {
        id: "project-1",
        title: "Prediction of chest diseases using x-ray images",
        links: [
            {
                name: "repo",
                url: "https://github.com/rodrigo-arenas/portfolio",
                icon: <AiFillGithub/>
            },
            {
                name: "fork",
                url: "https://github.com/rodrigo-arenas/portfolio/fork",
                icon: <BiGitRepoForked/>
            },
            {
                name: "subscription",
                url: "https://github.com/rodrigo-arenas/portfolio/subscription",
                icon: <AiFillEye/>
            }
        ],
        image:chest_diseases,
        description: "Developed an advanced web application leveraging the VGG 16 model for radiologists and patients to predict chest diseases from X-ray images. The platform utilizes deep learning for precise feature extraction, aiding in the early detection of various conditions. It includes intuitive visualization tools based on class activation mapping, Python, and TensorFlow libraries, enhancing diagnostic processes with actionable insights.",
        target: "_blank"
    }
]

export default projectConfig