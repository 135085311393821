export const info =`
Q: Where was Aditya Singh born?
A: Aditya Singh was born in Mumbai, India.

Q: what is your visa type?
A: Aditya is on F-1 visa which is a student Visa.


Q: Does Aditya Singh require H1 B sponsorship?
A: Yes, Aditya Singh requires H1 B sponsorship.

Q: What will be the role of the chatbot on Aditya Singh's portfolio website?
A: The chatbot will act as Aditya Singh's personal assistant, giving out information solely about him and not about anything else.

Q: How can someone contact Aditya Singh?
A: Aditya Singh can be contacted via email at asingh96@stevens.edu or through his mobile number at (774) 251-8085.

Q: Where does Aditya Singh currently live?
A: Aditya Singh currently lives in Hoboken, NJ, USA.

Q: What is Aditya Singh's LinkedIn profile URL?
A: Aditya Singh's LinkedIn profile can be found at www.aditya/linkedIn.com.

Q: What is Aditya Singh's educational background?
A: Aditya Singh obtained his Master of Science in Computer Science from Stevens Institute of Technology in Hoboken, USA, in December 2023, where he was awarded the Graduate Masters Scholarship in Spring 2022. He also holds a Bachelor of Engineering in Information Technology from the University of Mumbai, India, completed in June 2021.

Q: What skills and certifications does Aditya Singh have?
A: Aditya Singh has certifications in .Net Technology & Cross Platform Application Development (2017), Complete Android & Java Developer Course (2020), and Data Structures & Algorithm Certification (2022). His programming skills include Java, Python, JavaScript, MATLAB, HTML, CSS, NodeJS, REACT, C#, C, C++, TypeScript, JSX, PHP, with proficiency in tools and technologies like Slack, GitHub, Visual Studio, and many more. He is also skilled in unit testing with Jest and JUnit, and databases like MySQL, MongoDB, and PostgreSQL.

Q: Can you detail Aditya Singh's professional experience?
A: Aditya Singh has worked as a Software Engineer at Allegiant Air in New York City, NY, from September to December 2023, and at Techno-Comp INC in the same city from May to September 2023, among other roles. His experiences range from designing UI components using React and NextJS, improving data retrieval efficiency, writing unit test cases, to developing efficient, reusable React components and managing application deployments on AWS.

Q: What are some of Aditya Singh's notable academic projects?
A: Notable projects include a web application for the prediction of chest diseases using X-ray images, where he implemented the VGG 16 model for feature extraction and performed visualization using class activation mapping and TensorFlow Libraries. Another significant project is the City Watch Web App, a community-driven platform for reporting and accessing local events and news developed using ReactJS with MUI libraries and a backend in Node.js and Express server with a MongoDB database.

Q: What programming languages is Aditya Singh proficient in?
A: Aditya is proficient in a wide range of programming languages, including Java, Python, JavaScript, MATLAB, HTML, CSS, NodeJS, REACT, C#, C, C++, TypeScript, JSX, and PHP.

Q: What tools and technologies has Aditya Singh worked with?
A: Aditya has experience with various tools and technologies such as Slack, GitHub, Visual Studio, Eclipse, Jupyter Notebook, Bootstrap, AWS, JIRA, SourceTree, Spring Framework, Next.JS, Jenkins, Bitbucket, Postman, Swagger, Microsoft Power BI, Lucidchart, Roadmunk, Google Analytics, Linux, Unix, Tableau, and Excel.

Q: What databases is Aditya Singh familiar with?
A: Aditya Singh is familiar with several databases, including MySQL, MongoDB, and PostgreSQL.

Q: Describe Aditya Singh's experience at Allegiant Air.
A: At Allegiant Air, Aditya Singh worked as a Software Engineer, where he designed and implemented over 10 UI components for the travel management section using React and NextJS, improved data retrieval efficiency by 30% through the integration of GraphQL API endpoints, wrote more than 15 unit test cases using Jest, and played a key role in the CI/CD pipeline integration.

Q: What achievements did Aditya Singh accomplish at Techno-Comp INC?
A: While working at Techno-Comp INC as a Software Engineer, Aditya developed efficient, reusable React components in collaboration with Business Analysts, contributed to Agile sprint cycles, implemented over 14 Java Spring RESTful API endpoints, and managed application deployments on AWS, significantly improving system performance and development efficiency.

Q: Can you detail Aditya Singh's role at Bhaktivedanta Hospital & Research Institute?
A: At Bhaktivedanta Hospital & Research Institute, Aditya served as a Web Development Engineer, where he streamlined the Pathology department's sample collection and order processing, engineered robust REST APIs with Node.js for healthcare applications, and implemented MongoDB to enhance database efficiency.

Q: What was Aditya Singh's contribution to RAA Technocrats?
A: As a Software Engineer at RAA Technocrats, Aditya conducted comprehensive requirements analysis, enhanced web applications using PHP, HTML, CSS, and Bootstrap, and collaborated on unit testing, significantly boosting application reliability and performance.

Q: What are the key features of the City Watch Web App project?
A: The City Watch Web App, developed by Aditya, is a community-driven platform designed to report and access local events and news. It features a robust backend using Node.js and Express server, along with a MongoDB database, and was developed using ReactJS with MUI libraries for the frontend.

By presenting information in this detailed Q&A format, the chatbot is equipped to handle a broad spectrum of inquiries related to Aditya Singh's professional and academic background, offering users a comprehensive insight into his capabilities and achievements.
`;

