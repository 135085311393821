import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ReactGA from 'react-ga';
import NavBar from "./components/NavBar/NavBar";
import Home from "./pages/Home/Home";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills/Skills";
import Blogs from "./pages/Blogs"
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer/Footer";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InteractiveName from './assets/configs/InteractiveName'
import React from 'react'
import HomePage from "./components/HomePage";
import AnimatedBackground from "./components/AnimatedBackground";
if (typeof process.env.REACT_APP_TRACKING_ID !== 'undefined') {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
}


function App() {
    return (
        <Router>
            <div className="App">
                <NavBar/>
                
                <br />
                <ScrollToTop/>
                <Routes>
                <Route path={"/"} exact element={<Home/>}/>
                <Route path={"/homepage"} exact element={<HomePage/>}/>
                <Route path={"/background"} exact element={<AnimatedBackground/>}/>
                    <Route path={"/portfolio"} exact element={<Home/>}/>
                    <Route path={"/projects"} exact element={<Projects/>}/>
                    <Route path={"/chatbot"} exact element={<Blogs/>}/>
                    <Route path={"/skills"} exact element={<Skills/>}/>
                </Routes>
                <Footer/>
            </div>
        </Router>

    )

}

export default App;
